import * as React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import Layout from "../components/layout"
import '../components/layout.css'
import { 
  Box,
  Flex,
  Container,
  Heading,
  Text,
  Link,
  Button
} from "@chakra-ui/react"
import Seo from "../Seo"
import { ChevronRightIcon } from "@chakra-ui/icons"
import SliceZone from '../components/SliceZone/SliceZone'

import PageHero from "../components/PageHero/PageHero"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/linkResolver"

const TransportationPage = ({ data }) => {

  const document = data.allPrismicPage.edges[0].node.data;

  return (
    <Layout>
      <Seo title="Transportation | Plateau Forest Products" />
      <PageHero 
        heroImage={document.hero_image?.localFile}
        alt={document.hero_image.alt}
        pageTitle={document.page_title.text}
        pageSubtitle={document.page_subtitle.text}
      />
      <Box>
      <Flex p={{md: '1rem', lg: '2rem', xl: '3rem'}}
                    flexDirection="column"
                    height="100%"
                    justifyContent="space-between"
                    
                >
                    <Box>
                    <hr style={{ borderTop: '1px solid#113a22' }} />
                            <Box mb="8">
                                <Container maxW="container.lg">
                                    <Heading as="h2" color="leafy_green.500" fontSize={{base: '2rem', md: '4.5rem'}} textTransform="uppercase" my="8" textAlign="center">
                                        Transportation Resources
                                    </Heading>
                                    <Box textAlign='center'>
                                    <Text>Plateau Forest Products ships lumber products throughout North America. From cross-America long hauls to Intra-Canadian lanes and border crossings, we utilize all types of truck and trailer combinations including flatbeds, maxis, over-the-road vans, b-trains, LTLs, and intermodal containers.</Text>
                                    <Text>We retain the highest standards of safety and service to ensure we provide our customers with timely and exceptional service.
To qualify please select from the following shipping types below:</Text>
                                    </Box>
                                </Container>
                                
                                <Flex w="100%" flexWrap="wrap" justifyContent="center">
                                        <Box w={{base: '100%', lg: '50%', xl: '20%'}} p="4">
                                        <Link to="/transportation/us-based-carriers" as={GatsbyLink}>
                                          <Button variant="gold-button" isFullWidth> 
                                              Carrier Contract <ChevronRightIcon />
                                          </Button>
                                          </Link>
                                        </Box>
                                        <Box w={{base: '100%', lg: '50%', xl: '20%'}} p="4">
                                        <Link to="/transportation/us-based-brokers" as={GatsbyLink}>
                                          <Button variant="gold-button" isFullWidth>
                                             Broker Contract <ChevronRightIcon />
                                          </Button>
                                          </Link>
                                        </Box>
                                        {/* <Box w={{base: '100%', lg: '50%', xl: '20%'}} p="4">
                                        <Link to="/transportation/canadian-based-carriers" as={GatsbyLink}>
                                          <Button variant="gold-button" isFullWidth>
                                              Canada Based Carrier <ChevronRightIcon />
                                          </Button>
                                          </Link>
                                        </Box>
                                        <Box w={{base: '100%', lg: '50%', xl: '20%'}} p="4">
                                        <Link to="/transportation/intra-canadian-brokers" as={GatsbyLink}>
                                          <Button variant="gold-button" isFullWidth>
                                              Intra-Canadian Broker <ChevronRightIcon />
                                          </Button>
                                          </Link>
                                        </Box>
                                        <Box w={{base: '100%', lg: '50%', xl: '20%'}} p="4">
                                        <Link to="/transportation/intra-canadian-carriers" as={GatsbyLink}>
                                          <Button variant="gold-button" isFullWidth>
                                              Intra-Canadian Carrier <ChevronRightIcon />
                                          </Button>
                                          </Link>
                                        </Box> */}
                                      </Flex>
                            </Box>
                            
                        
                        <Box display={{base: 'none', md: 'block'}}><hr style={{ borderTop: '1px solid#113a22' }} /> </Box>
             </Box>
             </Flex>
      </Box>
        <Box>
          <SliceZone sliceZone={document.body}/>
        </Box>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicPage(filter: {uid: {eq: "transportation"}}) {
      edges {
        node {
          _previewable
          data {
            page_title {
              text
            }
            page_subtitle {
              text
            }
            hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            body {
              ... on PrismicPageDataBodyGreenCallToActionBlock {
                id
                slice_type
                primary {
                  block_color
                  yellow_title {
                    text
                  }
                  white_headline {
                    text
                  }
                  button_link {
                    uid
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyWhiteCallToActionBlock {
                id
                slice_type
                primary {
                  yellow_title {
                    text
                  }
                  white_headline {
                    text
                  }
                  button_link {
                    uid
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyWhatWeTradeBlock {
                id
                items {
                  short_description {
                    text
                  }
                  product_name {
                    text
                  }
                  product_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                    alt
                  }
                  button_link {
                    uid
                  }
                  button_label {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicPageDataBodyIndustryMembers {
                slice_type
                primary {
                  industry_partners {
                    document {
                      ... on PrismicIndustryPartners {
                        data {
                          industry_member {
                            industry_logo {
                              alt
                              copyright
                              url
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                                }
                              }
                            }
                            industry_link {
                              url
                              target
                            }
                          }
                        }
                      }
                    }
                  }
                }
                id
              }
              ... on PrismicPageDataBodyContentArea {
                id
                slice_type
                primary {
                  white_or_green_block_
                  content_title {
                    text
                  }
                  content {
                    raw
                  }
                }
              }
              ... on PrismicPageDataBodyCompanyOverviewBlock {
                id
                slice_type
                primary {
                  description {
                    text
                  }
                  block_title {
                    text
                  }
                }
                items {
                  brief_success_statement {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyTestimonials {
                id
                slice_type
                primary {
                  title {
                    text
                  }
                }
                items {
                  testimonial_text {
                    text
                  }
                  person_name {
                    text
                  }
                  company_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`


export default withPrismicPreview(TransportationPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])